import { navigateToUrl, registerApplication, start } from "single-spa";
import { registerMF } from "./core/interfaces";
import { EXTERNAL_PORTAL_URL, MF_LISTA  } from "./core/constantes";
import * as config from "./core/service-config/config";


const init = async () => {
  createApp();
};

const createApp = () => {

  const mfs: registerMF[] = MF_LISTA;

  for (const mf of mfs) {
    registerApp(mf);
  }

  start({
    urlRerouteOnly: true,
  });
}

const registerApp = (mf: registerMF) => {
  registerApplication({
    name: mf.nombre,
    app: () => {
      config.init(mf.envFile, mf.key)
      return System.import(`/${mf.host}/main`)
    },
    activeWhen: (location) => {
      routeExists();
      if (location.hash.startsWith(`#/${mf.active}`)) {
        return true;
      }
    },
  });
};

const routeExists = () => {
  const nameMf = MF_LISTA.some(mf => window.location.hash.includes(mf.nombre));
  if (window.location.hash === "#/" || window.location.hash === "" || !nameMf) {
    navigateToUrl(EXTERNAL_PORTAL_URL);
  }
}

init();



