export const init = (config: string, key: string) => {
    fetch(`assets/config-env/${config}`)
        .then((response) => response.json())
        .then(config => {
            saveState(key, config);
        });

};
// guardar en localstorage
const saveState = (key, data) => {
    try {
        localStorage.setItem(key, btoa(JSON.stringify(data)));
    } catch (error) {
        localStorage.setItem(key, JSON.stringify(null));
    }
};

export const loadState = (key) => {

    if (localStorage.getItem(key) === null) {
        return false
    }
    return true;

};