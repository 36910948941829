
export const EXTERNAL_PORTAL_URL = "https://portales.bancochile.cl/empresas"

export const MF_LISTA = [
    {
        "nombre": "cta-corriente-pj",
        "host": "mf/cta-corriente-pj",
        "active": "cta-corriente-pj",
        "envFile": "config-cce.json",
        "key": "cfg"
    },
    {
        "nombre": "cta-fan-emprende-pj",
        "host": "mf/cta-fan-emprende-pj",
        "active": "cta-fan-emprende-pj",
        "envFile": "config-cfe.json",
        "key": "cfe"
    },
    {
        "nombre": "cta-fan-emprende-png",
        "host": "mf/cta-fan-emprende-png",
        "active": "cta-fan-emprende-png",
        "envFile": "config-cfe.json",
        "key": "cfe"
    }
]
